<template>
  <!-- Menu Navigation -->
  <transition name="fade">
    <div v-show="isVisible" class="mobileBg" @click="triggerClose"></div>
  </transition>

  <transition name="slideright">
    <div v-show="isVisible" class="mobileMenu" @click="triggerClose">
      <div class="closeIcon">
        <CloseIcon />
      </div>
      <div class="link address" v-if="ethereum.address">
        <span></span>{{ profileAddressLabel }}
      </div>
      <!-- The user is not connected to a signing provider. -->
      <div
        class="link"
        v-if="!ethereum.address"
        @click="showWalletModalDisplay"
      >
        {{ walletPrompt }}
      </div>
      <div v-if="!isHome" class="link" @click="routeTo('/')">Home</div>
      <a v-if="isHome" href="#about" class="link" v-smooth-scroll>About</a>
      <a v-if="isHome" href="#specs" class="link" v-smooth-scroll>Specs</a>
      <a v-if="isHome" href="#club" class="link" v-smooth-scroll
        >Club Benefits</a
      >
      <a v-if="isHome" href="#roadmap" class="link" v-smooth-scroll>Roadmap</a>
      <a v-if="isHome" href="#fairdrop" class="link" v-smooth-scroll
        >Fair Drop</a
      >
      <a v-if="isHome" href="#faq" class="link" v-smooth-scroll>FAQ</a>
      <!-- <a class="link" @click="routeTo('/claim')">Claim</a> -->
      <div
        v-if="ethereum.address"
        class="link"
        @click="routeTo(`/my-frens/${ethereum.address}`)"
      >
        My Frens
      </div>

      <div class="link" @click.self="openUrl('https://discord.gg/CBuv4q3d')">
        <DiscordIcon />
      </div>
      <div class="link" @click="openUrl('https://twitter.com/AstroFrensNFT')">
        <TwitterIcon />
      </div>
    </div>
  </transition>
</template>
<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { formatAddress } from '/src/utility/format';
import CloseIcon from '/src/components/icons/CloseIcon.vue';
import DiscordIcon from '/src/components/icons/DiscordIcon.vue';
import TwitterIcon from '/src/components/icons/TwitterIcon.vue';

export default {
  components: { CloseIcon, DiscordIcon, TwitterIcon },

  emits: ['toggleVisibility', 'showWalletModal'],

  props: {
    isVisible: Boolean,
    isHome: Boolean
  },

  setup(props, context) {
    const router = useRouter();
    const store = useStore();
    const triggerClose = () => {
      window.onscroll = function() {};
      context.emit('toggleVisibility');
    };

    const openUrl = url => {
      window.open(url, '_blank');
    };

    const routeTo = url => {
      router.push(url);
    };

    const ethereum = computed(() => {
      return store.state.ethers;
    });

    const profileAddressLabel = computed(() => {
      return formatAddress(ethereum.value.address);
    });

    const walletPrompt = computed(() => {
      if (!ethereum.value.provider) {
        return '...';
      }
      return ethereum.value.hasLocalProvider
        ? 'Connect Wallet'
        : 'Install a Wallet';
    });

    const showWalletModalDisplay = () => {
      context.emit('showWalletModal');
    };

    return {
      triggerClose,
      openUrl,
      routeTo,
      ethereum,
      profileAddressLabel,
      walletPrompt,
      showWalletModalDisplay
    };
  }
};
</script>
<style scoped lang="scss">
.mobileMenu {
  position: fixed;
  top: 10px;
  right: 10px;
  bottom: 10px;
  z-index: 1000;
  padding: 20px 60px 20px 20px;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  border-radius: 30px;
  background-color: #1b2050;
  color: white;
  display: flex;

  .link {
    display: flex;
    padding: 15px 20px;
    align-items: center;
    border-radius: 40px;
    transition: background-color 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    background-color: transparent;
    border: none;
    height: fit-content;
    white-space: nowrap;
    margin-bottom: 10px;

    &:hover {
      cursor: pointer;
      background-color: hsla(0, 0%, 100%, 0.1);
    }

    &:active {
      background-color: hsla(0, 0%, 100%, 0.2);
    }
  }

  .address {
    border: 2px solid hsla(0, 0%, 100%, 0.5);
    background-color: hsla(0, 0%, 100%, 0.1);
    cursor: default;
    &:hover {
      background-color: transparent !important;
    }

    &:active {
      background-color: transparent !important;
    }

    span {
      padding: 3px;
      border-radius: 50%;
      background-color: white;
      margin: auto;
      margin-right: 10px;
    }
  }

  .closeIcon {
    position: absolute;
    left: auto;
    top: 0%;
    right: 0%;
    bottom: auto;
    display: flex;
    width: 60px;
    height: 60px;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    transition: background-color 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
    color: #fff;
    cursor: pointer;

    &:hover {
      background-color: hsla(0, 0%, 100%, 0.1);
    }

    &:active {
      background-color: hsla(0, 0%, 100%, 0.2);
    }
  }
}

.mobileBg {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1000vh;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);
}

// Animations
.slideright-leave-active,
.slideright-enter-active {
  transition: all 0.5s ease;
}

.slideright-enter-from,
.slideright-leave-to {
  transform: translateX(100vw);
}

.fade-leave-active,
.fade-enter-active {
  transition: all 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
