<template>
  <div class="footer">
    <div class="logo" href="#splash" v-smooth-scroll>
      <img
        src="https://astrofrens-metadata.s3.amazonaws.com/assets/astro-frens-logo.svg"
      />
    </div>
    <a href="/provenance" class="link">
      Provenance
    </a>
    <a href="#about" class="link" v-smooth-scroll @click="routeTo('/')"
      >About</a
    >
    <a href="#specs" class="link" v-smooth-scroll>Specs</a>
    <a href="#club" class="link" v-smooth-scroll>Club Benefits</a>
    <a href="#roadmap" class="link" v-smooth-scroll>Roadmap</a>
    <a href="#fairdrop" class="link" v-smooth-scroll>Fair Drop</a>
    <a href="#faq" class="link" v-smooth-scroll>FAQ</a>
    <a href="#mint" class="link" v-smooth-scroll>Mint</a>
    <div class="link" @click="openUrl('https://discord.gg/CBuv4q3d')">
      <DiscordIcon />
    </div>
    <div class="link" @click="openUrl('https://twitter.com/AstroFrensNFT')">
      <TwitterIcon />
    </div>
  </div>
  <div class="terms">
    <p class="top" href="#splash" v-smooth-scroll>Back to top</p>
    <p @click="routeTo('/terms')">Terms & Conditions</p>
  </div>
</template>
<script>
import DiscordIcon from '/src/components/icons/DiscordIcon.vue';
import TwitterIcon from '/src/components/icons/TwitterIcon.vue';
export default {
  components: {
    DiscordIcon,
    TwitterIcon
  },

  methods: {
    openUrl(url) {
      window.open(url, '_blank');
    },
    routeTo(path) {
      this.$router.push(path);
    }
  }
};
</script>
<style scoped lang="scss">
.footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 20px;
  padding-bottom: 0px;

  .logo {
    width: 240px;
    max-width: 100%;
    margin-right: auto;
    margin-bottom: 0px;
    cursor: pointer;
    transition: all 500ms ease;

    &:active {
      transform: scale(0.9);
    }
  }

  .link {
    display: flex;
    padding: 15px 20px;
    align-items: center;
    border-radius: 40px;
    transition: background-color 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    background-color: transparent;
    border: none;
    height: fit-content;
    white-space: nowrap;

    &:hover {
      cursor: pointer;
      background-color: hsla(0, 0%, 100%, 0.1);
    }

    &:active {
      background-color: hsla(0, 0%, 100%, 0.2);
    }
  }
}

.terms {
  display: flex;
  justify-content: flex-end;
  padding: 20px;
  padding-top: 0;

  p {
    cursor: pointer;
    color: white;
    opacity: 0.7;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  .top {
    text-decoration: none;
    margin-right: 20px;
  }
}

@media (max-width: 1200px) {
  .footer {
    flex-wrap: wrap;
    justify-content: center;
    .logo {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-bottom: 10px;
    }
  }

  .terms {
    justify-content: center;
  }

  .top {
    display: none;
  }
}
</style>
